import {
  DeleteOutline as DeleteIcon,
  AttachFile as FileIcon,
} from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import {
  isApproved,
  isAwarded,
  isPending,
  isStep_0_5,
} from "../helper/pr-config";
import moment from "moment";

export const costMarginTableConfig = (
  itemList,
  editAllowed,
  submittedForApproval,
  openDocumentDrawer,
  onRemoveRow,
  onChangeRowValues,
  approvers,
  step,
  status,
  version,
) => {
  const isEditable = () =>
    editAllowed &&
    !submittedForApproval &&
    !isApproved(status) &&
    !isAwarded(status);

  const displayApprover = (id) => {
    const approver = approvers?.find((a) => a._id === id);
    return approver ? `${approver.username}-${approver.name}` : "-";
  };

  let columns = [
    {
      name: "Document",
      width: "70px",
      cell: (row, index) => (
        <>
          <IconButton onClick={() => openDocumentDrawer(row, index)}>
            <FileIcon />
          </IconButton>
          <small>{row.documents?.length ?? 0}</small>
        </>
      ),
    },
    {
      name: "Item",
      width: "210px",
      cell: (row, index) => {
        if (isEditable() && (isPending(row.status) || isStep_0_5(step))) {
          return (
            <FormControl error={!row.description}>
              <Select
                key={`select-${index}`}
                variant={"outlined"}
                value={row.description}
                onChange={(e) =>
                  onChangeRowValues(index, e.target.value, "description")
                }
                IconComponent={ArrowDownIcon}
              >
                {itemList.map((element, i) => (
                  <MenuItem
                    key={`option-${i}`}
                    value={element.toUpperCase()}
                    selected={row.description === element.toUpperCase()}
                  >
                    {element.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              {!row.description && (
                <FormHelperText>Item can not be blank</FormHelperText>
              )}
            </FormControl>
          );
        }
        return <>{row.description}</>;
      },
    },
  ];

  if (version > 1) {
    columns = [
      ...columns,
      {
        name: "Description",
        width: "300px",
        cell: (row, index) => {
          if (isEditable() && (isPending(row.status) || isStep_0_5(step))) {
            return (
              <FormControl error={!row.description2}>
                <InputBase
                  variant={"outlined"}
                  value={row.description2}
                  onChange={(e) =>
                    onChangeRowValues(index, e.target.value, "description2")
                  }
                />
                {!row.description2 && (
                  <FormHelperText>Description can not be blank</FormHelperText>
                )}
              </FormControl>
            );
          }
          return <>{row.description2}</>;
        },
      },
    ];
  }

  columns = [
    ...columns,
    ...[
      {
        name: "Cost",
        width: "110px",
        right: true, // (!isEditable() || !isStep_0_5(step)),
        cell: (row, index) => {
          if (isEditable() && (isPending(row.status) || isStep_0_5(step))) {
            return (
              <InputBase
                type={"number"}
                variant={"outlined"}
                className={"dollar"}
                value={row.cost ? row.cost : undefined}
                onChange={(e) =>
                  onChangeRowValues(index, e.target.value, "cost")
                }
                inputProps={{
                  sx: { textAlign: "start !important;" },
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            );
          }
          return <>{convertThisToCurrency(row.cost?.toFixed(2))}</>;
        },
      },
      {
        name: "Price",
        width: "110px",
        right: true, // (!isEditable() || !isStep_0_5(step)),
        cell: (row, index) => {
          if (isEditable() && (isPending(row.status) || isStep_0_5(step))) {
            return (
              <InputBase
                type={"number"}
                variant={"outlined"}
                className={"dollar"}
                value={row.sellingPrice ? row.sellingPrice : undefined}
                inputProps={{
                  sx: { textAlign: "start !important;" },
                }}
                onChange={(e) =>
                  onChangeRowValues(index, e.target.value, "sellingPrice")
                }
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            );
          }
          return <>{convertThisToCurrency(row.sellingPrice?.toFixed(2))}</>;
        },
      },
      {
        name: "Vendor",
        width: "250px",
        cell: (row, index) => {
          if (isEditable() && (isPending(row.status) || isStep_0_5(step))) {
            return (
              <InputBase
                variant={"outlined"}
                value={row.vendor}
                onChange={(e) =>
                  onChangeRowValues(index, e.target.value, "vendor")
                }
              />
            );
          }
          return <>{row.vendor}</>;
        },
      },
      {
        name: "Remark",
        width: "300px",
        cell: (row, index) => {
          if (isEditable() && (isPending(row.status) || isStep_0_5(step))) {
            return (
              <InputBase
                variant={"outlined"}
                value={row.remarks}
                onChange={(e) =>
                  onChangeRowValues(index, e.target.value, "remarks")
                }
              />
            );
          }
          return <>{row.remarks}</>;
        },
      },
      {
        name: "Status",
        width: "90px",
        cell: (row, index) => <>{row.status}</>,
      },
      {
        name: "Approved By",
        minWidth: "250px",
        cell: (row, index) => <>{displayApprover(row.approvedBy)}</>,
      },
      {
        name: "Approved Date",
        width: "170px",
        wrap: true,
        cell: (row) => (
          <>
            {row.approvedDate
              ? moment(row.approvedDate).format("DD/MM/YYYY HH:mm:ss")
              : "-"}
          </>
        ),
      },
    ],
  ];

  if (isEditable()) {
    columns.unshift({
      name: "",
      width: "50px",
      cell: (row, index) =>
        !isApproved(row.status) && (
          <IconButton onClick={() => onRemoveRow(row, index)}>
            <DeleteIcon />
          </IconButton>
        ),
    });
  }
  return columns;
};
