import { IconButton, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import { showSymbol } from "../../../helper/show-symbol";
import moment from "moment";
import { DeleteOutline } from "@mui/icons-material";

export const fnbEventMenuTableColumnConfig = (
  { allowedViewDetail, allowedDelete, allowedEnableDisable },
  handleNameClick,
  handleDeleteFnbEventMenu,
  handleEditFnbEventMenu,
) => {
  let columns = [
    {
      name: "No.",
      width: "90px",
      selector: (row) => row.eventMenuNo,
      cell: (row) => {
        if (allowedViewDetail) {
          return (
            <Link
              to={`/app/fnb-event-menu/card/${row._id}`}
              onClick={(e) => handleNameClick(row._id)}
            >
              {row.eventMenuNo}
            </Link>
          );
        } else {
          return <>{row.eventMenuNo}</>;
        }
      },
      sortable: true,
      sortField: "eventMenuNo",
    },
  ];

  if (allowedDelete) {
    columns = [
      ...columns,
      {
        name: "",
        right: true,
        width: "50px",
        cell: (row) => {
          return (
            <>
              {allowedDelete && (
                <>
                  <IconButton onClick={(e) => handleDeleteFnbEventMenu(row)}>
                    <DeleteOutline />
                  </IconButton>
                </>
              )}
            </>
          );
        },
      },
    ];
  }

  columns = [
    ...columns,
    {
      name: "Menu",
      minWidth: "180px",
      selector: (row) => row.menuName,
      sortable: true,
      wrap: true,
      sortField: "menuName",
    },
    {
      name: "Outlet",
      width: "250px",
      selector: (row) =>
        row.fnbOutlet
          ?.map((o) => `${o.name}`)
          .toString()
          .toUpperCase(),
      sortable: true,
      wrap: true,
      sortField: "storeCode",
    },
    {
      name: "Created By",
      width: "180px",
      selector: (row) =>
        row.createdByData?.map((s) => `${s.username}-${s.name}`).toString(),
      sortable: true,
      wrap: true,
      sortField: "createdBy",
    },
    {
      name: "Created At",
      width: "170px",
      wrap: true,
      selector: (row) => row.createdAt,
      cell: (row, index, column, id) => {
        return moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss");
      },
      sortable: true,
      sortField: "createdAt",
    },
  ];

  if (allowedEnableDisable) {
    columns = [
      ...columns,
      {
        name: "Enable",
        width: "80px",
        center: true,
        selector: (row) => row.enabled,
        cell: (row) => {
          return (
            <Switch
              checked={row.enabled}
              onChange={(e, value) => handleEditFnbEventMenu(row, true, value)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
        sortable: true,
        sortField: "enabled",
      },
    ];
  }

  return columns;
};
