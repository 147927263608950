import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
    Backdrop, Typography, CircularProgress, Grid,
    TextField, Button, FormControlLabel, Switch, Autocomplete, IconButton, Box, InputAdornment
} from "@mui/material";
import { Add, Clear, KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import useStyles from "./styles";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useStoreDispatch } from "../../context/StoreContext";
import { useUserState } from "../../context/UserContext";
import moment from "moment";
import DateController from "../../components/DateController";
const FnbEventBookingCard = (props) => {
    const classes = useStyles();
    const { userInfo } = useUserState();

    // handle loading & http
    const { isLoading, httpRequestError: error,
        responseData, sendRequest } = useHttpRequest();

    // page authorization
    const { auth } = useRoleAuthorization();

    const { eventBookingId } = useParams(); // url param => eventBookingId when editing

    // handling form when adding / editing
    const { handleSubmit, register, formState: { errors: formErrors },
        control, setError, getValues, setValue } = useForm({ mode: "all" });

    // un-assigned sub-con list
    const [chefList, setChefList] = useState([]);
    const [message, setMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [currentBooking, setCurrentBooking] = useState(null); // when edit
    const [currentBookingNo, setCurrentBookingNo] = useState(null);
    const [resourceList, setResourceList] = useState([]);
    const [rowsInvalid, setRowsInvalid] = useState(false);

    //store data
    const storeDispatcher = useStoreDispatch();
    // const { store, storeList } = useStoreState();
    const [fnbOutlets, setFnbOutlets] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [date, setDate] = useState(null);

    // const durationList = Object.values(FNB_EVENT_BOOKING_DURATIONS);
    const [durationList, setDurationList] = useState(null);
    const [duration, setDuration] = useState(null);

    const [paxList, setPaxList] = useState([]);
    const [noOfPax, setNoOfPax] = useState(null);

    const [timeSlotList, setTimeSlotList] = useState([]);
    const [avaiTimeSlotList, setAvaiTimeSlotList] = useState([]);
    const [requestedByList, setRequestedByList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [specialRequestTypes, setSpecialRequestTypes] = useState([]);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [fnbEventBookingStart, setFnbEventBookingStart] = useState(null);
    const [fnbEventBookingEnd, setFnbEventBookingEnd] = useState(null);
    const [fnbEventBookingMinSlotInMinute, setFnbEventBookingMinSlotInMinute] = useState(null);
    const [fnbEventMenuBaseUrl, setFnbEventMenuBaseUrl] = useState(null);
    const [fnbEventMenuSurveyBaseUrl, setFnbEventMenuSurveyBaseUrl] = useState(null);
    const [fnbEventMenuBillSettlementBaseUrl, setFnbEventMenuBillSettlementBaseUrl] = useState(null);
    const [eventMenuNo, setEventMenuNo] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [menuUpdate, setMenuUpdate] = useState(false);
    const [specialRequest, setSpecialRequest] = useState(null);
    const [requestedBy, setRequestedBy] = useState(null);
    const [selectedRequestedBy, setSelectedRequestedBy] = useState(null);
    const [totalBill, setTotalBill] = useState(null);
    const [approved, setApproved] = useState(false);
    const [enabledSwitchValue, setEnabledSwitchValue] = useState(true); // enable switch => default true
    const [selfCollectionSwitchValue, setSelfCollectionSwitchValue] = useState(false);
    
    const [currentMenu, setCurrentMenu] = useState(null);

    useEffect(() => {
        document.title = "New - F&B Event Booking Card";
        loadPrerequisites();

        // redirect to default page if not authorized
        if (eventBookingId && auth.isPrivilegeDataLoaded() && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.view_detail)) {
            props.history.push('/app/dashboard');
        } else if (eventBookingId && auth.isPrivilegeDataLoaded() && auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.menu_update, false)) {
            setMenuUpdate(true);
        }

    }, []);

    useEffect(() => {
        if (fnbOutlets && currentBooking) {
            setSelectedStore(fnbOutlets.filter(outlet => outlet.code === currentBooking.storeCode).pop())
        } else if (fnbOutlets && !currentBooking) {
            setSelectedStore(fnbOutlets.filter(outlet => outlet.isDefault === true).pop())
        }
    }, [currentBooking, fnbOutlets])

    useEffect(() => {
        if (requestedByList && currentBooking) {
            setSelectedRequestedBy(requestedByList.filter(reqBy => reqBy._id === currentBooking.requestedBy).pop())
        }
    }, [currentBooking, requestedByList])

    useEffect(() => {
        if (menuList && currentBooking) {
            let menu = menuList.filter(menu => menu.eventMenuNo === currentBooking.eventMenuNo).pop();

            if(!menu && approved){
                menu = currentMenu;
            }
            
            setSelectedMenu(menu);
        }
    }, [currentBooking, menuList])

    useEffect(() => {
        if (!selectedStore) {
            return;
        }

        setValue('storeCode', selectedStore.code)
    }, [selectedStore])

    // show feedback messages
    useEffect(() => {
        if (message) {
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: message,
                willClose: navigateToList
            });
            setMessage('');
        }
    }, [message, responseData]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: responseData?.message
            });
        }
    }, [error, responseData]);

    useEffect(() => {
        if (warningMessage) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: warningMessage
            });
            setWarningMessage('');
        }
    }, [warningMessage, responseData]);

    // get current matrix in edit view
    useEffect(() => {
        if (!isLoading && !responseData && eventBookingId) {
            sendRequest(`/v1/fnb-event-booking/get-by-booking-id/` + eventBookingId, 'GET');
        }
    }, [eventBookingId]);

    useEffect(() => {
        if (eventBookingId && fnbEventMenuSurveyBaseUrl) {
            setValue('fnbEventMenuSurveyUrl', `${fnbEventMenuSurveyBaseUrl}/${eventBookingId}`)
        }
    }, [fnbEventMenuSurveyBaseUrl, eventBookingId])

    useEffect(() => {
        if (eventBookingId && fnbEventMenuBillSettlementBaseUrl) {
            setValue('fnbEventMenuBillSettlementUrl', `${fnbEventMenuBillSettlementBaseUrl}/${eventBookingId}`)
        }
    }, [fnbEventMenuBillSettlementBaseUrl, eventBookingId])

    // set form values when edit view
    useEffect(() => {
        if (eventBookingId && responseData?.eventBooking) {
            const eventBooking = responseData?.eventBooking;
            document.title = `${eventBooking.eventName} - F&B Event Booking Card`;
            setCurrentBooking(eventBooking);
            setCurrentBookingNo(eventBooking.eventBookingNo);
            setValue('eventBookingNo', eventBooking.eventBookingNo || '');
            setValue('eventName', eventBooking.eventName || '');
            setValue('storeCode', eventBooking.storeCode || '');
            setValue('date', eventBooking.date || '');
            setValue('gCalendarEventID', eventBooking.gCalendarEventID);
            setDate(eventBooking.date);
            setEventMenuNo(eventBooking.eventMenuNo);
            setStartTime(eventBooking.startTime);
            setEndTime(eventBooking.endTime);
            setDuration(eventBooking.duration);
            setNoOfPax(eventBooking.noOfPax);
            setSpecialRequest(eventBooking.specialRequest);
            setRequestedBy(eventBooking.requestedBy);
            setValue('requestedBy', eventBooking.requestedBy);
            setTotalBill(eventBooking.totalBill);
            setValue('remark', eventBooking.remark || '');
            setValue('createdBy', eventBooking.createdBy || '');
            setValue('updatedBy', eventBooking.updatedBy || '');
            loadMenuList(eventBooking.storeCode);
            setApproved(!eventBooking.approvedBy ? false : true);
            setValue('enabled', eventBooking.enabled);
            setEnabledSwitchValue(eventBooking.enabled);
            setCurrentMenu(responseData?.eventMenu);
            setValue("selfCollection", eventBooking.selfCollection);
            setSelfCollectionSwitchValue(eventBooking.selfCollection);
        }
    }, [responseData]);

    useEffect(() => {
        const invalid = resourceList
            .findIndex(r => !r.resourceCode || !r.claimValue) > -1;
        setRowsInvalid(invalid);
    }, [resourceList]);

    useEffect(() => {
        const invalid = !selectedStore || !startTime || !duration || !noOfPax || !requestedBy;
        setRowsInvalid(invalid);
    }, [selectedStore, startTime, duration, noOfPax, requestedBy]);

    useEffect(() => {
        if (selectedStore == null && selectedStore == undefined) {
            return;
        }

        setSelectedMenu(null);
        setMenuList([]);
        loadMenuList(selectedStore.code);
    }, [selectedStore])

    useEffect(() => {
        loadAvailableTimeSlots();
    }, [selectedStore, date]);

    useEffect(() => {
        loadAvailableDurations();
    }, [date, selectedStore, avaiTimeSlotList, startTime])

    const navigateToList = () => {
        if (!eventBookingId)
            window.location.href = '#/app/fnb-event-booking/list';
    }

    function prepareTimeSlots() {
        let timeSlots = [];
        let start = new Date(`1970/01/01 ${fnbEventBookingStart}:00`).getTime();
        let end = new Date(`1970/01/01 ${fnbEventBookingEnd}:00`).getTime();

        while (start < end) {
            start = start + (fnbEventBookingMinSlotInMinute * 60 * 1000);
            const m = moment(new Date(start));
            timeSlots.push(m.format('h:mm a'));
        }

        setTimeSlotList(timeSlots);
    }

    function getNumFromTime(strTime) {
        let noonInc = strTime.toUpperCase().includes("PM") ? 12 : 0;
        let hr = Number(strTime.toUpperCase().replace(" AM", "").replace(" PM", "").split(":")[0]);
        let min = Number(strTime.toUpperCase().replace(" AM", "").replace(" PM", "").split(":")[1]);
        hr = hr + (hr == 12 ? 0 : noonInc);

        return Number(`${hr}.${(min / 60) * 100}`);
    }

    function getEndTime() {
        let start = new Date(`1970/01/01 ${startTime}`).getTime();
        let end = new Date(start + (parseInt(duration.replace(" hr", "")) * 60 * 60 * 1000));
        const m = moment(end);
        return m.format('hh:mm A');
    }

    const handleSubmitForm = async (data, e) => {
        e.preventDefault();

        if (!!specialRequest && (requestedBy || '') == '') {
            setWarningMessage(`Requested By cannot be empty.`);
            return;
        }

        let eventEndTime = getEndTime();
        const booking = {
            eventBookingNo: data.eventBookingNo,
            eventName: data.eventName.toUpperCase(),
            storeCode: data.storeCode,
            storeName: (`${selectedStore.location}@${selectedStore.name}`).toUpperCase(),
            date: data.date,
            eventMenuNo: eventMenuNo,
            eventMenuName: (!selectedMenu ? '' : selectedMenu.menuName).toUpperCase(),
            eventMenuUrl: (!selectedMenu ? '' : `${fnbEventMenuBaseUrl}/${selectedMenu._id}`),
            startTime: startTime,
            endTime: eventEndTime,
            startTimeInNum: getNumFromTime(startTime),
            endTimeInNum: getNumFromTime(eventEndTime),
            duration: duration,
            noOfPax: noOfPax,
            specialRequest: specialRequest?.toUpperCase(),
            requestedBy: data.requestedBy,
            totalBill: (!selectedMenu ? 0 : selectedMenu.pricePerPax * Number(noOfPax.toUpperCase().replace("ADULTS", "").replace("ADULT", ""))),
            remark: data.remark.toUpperCase(),
            gCalendarEventID: data.gCalendarEventID,
            enabled: data.enabled,
            selfCollection: data.selfCollection,
            createdBy: (eventBookingId && currentBooking && currentBooking._id ? data.createdBy : userInfo._id),
            updatedBy: (eventBookingId && currentBooking && currentBooking._id ? userInfo._id : undefined),
        };

        let savedBooking = undefined, message = '';
        if (currentBooking && eventBookingId) { // when edit
            booking._id = currentBooking._id;
            const result = await sendRequest(`/v1/fnb-event-booking/${eventBookingId}`,
                "PUT", booking);
            if (result?.status === 200 && result?.data?.eventBooking) {
                message = `F&B event booking  ${result.data.eventBooking.eventName} has been updated.`;
                savedBooking = result?.data?.eventBooking;
            }
        } else {
            const result = await sendRequest(`/v1/fnb-event-booking`, "POST", booking);
            if (result?.status === 201 && result?.data?.eventBooking) {
                message = `New F&B event booking ${result.data.eventBooking.eventName} has been created.`;
                savedBooking = result?.data?.eventBooking;
            }
        }

        setMessage(message);
    }

    const previewMenu = () => {
        window.open(`${fnbEventMenuBaseUrl}/${selectedMenu._id}`, "Menu Preview", "menubar=0,resizable=0,width=605,height=795");
    }

    const onChangeDate = (date) => {
        setValue('date', date);
        setDate(date);

        setStartTime('');
        setDuration('');
    }

    const loadAvailableDurations = () => {
        if (startTime == undefined || startTime == '') {
            setDurationList([]);
            return;
        }

        let durations = [];
        let startTimeInNumber = getNumFromTime(startTime);
        for (let i = 0; i < 5; i++) {
            if ((avaiTimeSlotList.filter((e) => { return getNumFromTime(e) == (startTimeInNumber + i) }).length > 0)) {
                durations.push(`${i + 1} HR`);
            } else {
                break;
            }
        }

        setDurationList(durations);
    }

    function subtractHoursFromDatetime(datetime, hours) {
        const dateObj = new Date(datetime);
        dateObj.setHours(dateObj.getHours() - hours);
        return dateObj.getTime();
    }

    const loadAvailableTimeSlots = () => {
        if (selectedStore == undefined || date == undefined) {
            setAvaiTimeSlotList([]);
            return;
        }

        sendRequest(`/v1/fnb-event-booking/get-occupied-time-slots/${selectedStore.code}/${date}/${currentBookingNo}`, "GET", {})
            .then(response => {
                let occupiedTimeSlots = response.data.occupiedTimeSlots;
                let timeSlots = [];
                let start = new Date(`1970/01/01 ${fnbEventBookingStart}:00`).getTime();
                let end = new Date(`1970/01/01 ${fnbEventBookingEnd}:00`).getTime();
                end = subtractHoursFromDatetime(end, 1);

                while (start <= end) {
                    const m = moment(new Date(start));
                    const timeInString = m.format('hh:mm A');
                    const timeInNum = getNumFromTime(timeInString);

                    if (occupiedTimeSlots.filter((e) => { return timeInNum == (e.startTimeInNum - 0.5) || (timeInNum >= e.startTimeInNum && timeInNum < e.endTimeInNum) }).length == 0) {
                        timeSlots.push(timeInString);
                    }

                    start = start + (fnbEventBookingMinSlotInMinute * 60 * 1000);
                }

                setAvaiTimeSlotList(timeSlots || []);
            });
    }

    const loadPrerequisites = () => {
        sendRequest(`/v1/fnb-event-booking/get-prerequisites`, "GET", {})
            .then(response => {
                let prerequisites = response.data.prerequisites;
                setFnbOutlets(prerequisites.fnbOutlets);
                setFnbEventBookingStart(prerequisites.fnbEventBookingStart);
                setFnbEventBookingEnd(prerequisites.fnbEventBookingEnd);
                setFnbEventBookingMinSlotInMinute(prerequisites.fnbEventBookingMinSlotInMinute);
                setFnbEventMenuBaseUrl(prerequisites.fnbEventMenuBaseUrl);
                setFnbEventMenuSurveyBaseUrl(prerequisites.fnbEventMenuSurveyBaseUrl);
                setFnbEventMenuBillSettlementBaseUrl(prerequisites.fnbEventMenuBillSettlementBaseUrl);
                setSpecialRequestTypes(prerequisites.fnbEventMenuSpecialRequestTypes);
                setRequestedByList(prerequisites.fnbEventMenuRequestedByList);
                setPaxList(Object.values(prerequisites.noOfPaxList));
            });
    }

    const onChangeStore = (newValue) => {
        setValue('storeCode', newValue.code)
        setSelectedStore(newValue);
        setStartTime('');
        setDuration('');
    }

    const onChangeRequestedBy = (newValue) => {
        setValue('requestedBy', newValue._id)
        setRequestedBy(newValue._id);
        setSelectedRequestedBy(newValue);
    }

    function loadMenuList(storeCode) {
        sendRequest(`/v1/fnb-event-booking/fnb-event-menu-list/${storeCode}`, "GET", {})
            .then(response => {
                setMenuList(response.data.eventMenus || []);
            });
    }

    function onChangeMenu(data) {
        setEventMenuNo(data?.eventMenuNo || '');
        setSelectedMenu(data);
    }

    function onChangeTime(newValue) {
        setStartTime(newValue);
        setDuration('');
    }

    function handleSurveyUrlFocus(event){
        event.target.select();
    }

    function handleSelfCollectionChange(value){
        setSelfCollectionSwitchValue(value);

        if(value){
            setValue('eventName', 'SELF COLLECTION');
        }
    }

    return (<>
        {isLoading && <Backdrop open={isLoading}><CircularProgress /></Backdrop>}
        {
            <form>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={"h1"}>F&B Event Booking Card
                            {currentBooking ? ` - ${currentBooking.eventName}` : ` - New`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
                        <Button onClick={() => props.history.push('/app/fnb-event-booking/list')}>Cancel</Button>
                        {
                            ((!currentBooking && !eventBookingId && auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.add)) || (currentBooking && eventBookingId && auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit)) || (currentBooking && eventBookingId && auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.menu_update)))
                            &&
                            <Button
                                disabled={rowsInvalid || approved}
                                className={"primary"} type={"button"}
                                onClick={handleSubmit(handleSubmitForm)}>
                                Save
                            </Button>
                        }
                        {
                            <Button
                                disabled={!selectedMenu}
                                className={"primary"} type={"button"}
                                onClick={previewMenu}>
                                Menu Preview
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={"form"}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Typography variant={"h2"}>General</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"storeCode"}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={fnbOutlets}
                                                    getOptionLabel={(store) => `${store.name}`}
                                                    value={selectedStore}
                                                    onChange={(e, newValue) =>
                                                        onChangeStore(newValue)
                                                    }
                                                    disableClearable={true}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={"Outlet"}
                                                            variant={"outlined"}
                                                        />}
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"event-menu-no"}
                                                    disabled={approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={menuList}
                                                    getOptionLabel={(m) => m.menuName}
                                                    value={selectedMenu}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={"Menu"}
                                                            variant={"outlined"}
                                                            autoComplete="off" />}
                                                    onChange={(event, data) => {
                                                        onChangeMenu(data)
                                                    }}
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField id={"eventName"} label={"Event"} variant={"outlined"}
                                                    {...register("eventName", {
                                                        required: { value: true, message: "Name cannot be blank." },
                                                    })}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.eventName}
                                                    helperText={formErrors?.eventName?.message}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                              <DateController
                                                control={control}
                                                label={'Event Date'}
                                                name={'date'}
                                                required={true}
                                                value={date}
                                                onChange={onChangeDate}
                                                disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"startTime"}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={avaiTimeSlotList}
                                                    getOptionLabel={(option) => option}
                                                    value={startTime}
                                                    onChange={(e, newValue) => onChangeTime(newValue)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params} label={"Event Time"} variant={"outlined"}
                                                        />
                                                    }
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"duration"}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={durationList}
                                                    getOptionLabel={(option) => option}
                                                    value={duration}
                                                    onChange={(e, newValue) => setDuration(newValue)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params} label={"Duration"} variant={"outlined"}
                                                        />
                                                    }
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"noOfPax"}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={paxList}
                                                    getOptionLabel={(option) => option}
                                                    value={noOfPax}
                                                    onChange={(e, newValue) => setNoOfPax(newValue)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params} label={"No. of Pax"} variant={"outlined"}
                                                        />
                                                    }
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"requestedBy"}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={requestedByList}
                                                    getOptionLabel={(option) => `${option.username}-${option.name}`}
                                                    value={selectedRequestedBy}
                                                    onChange={(e, newValue) =>
                                                        onChangeRequestedBy(newValue)
                                                    }
                                                    disableClearable={true}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={"Requested By"}
                                                            variant={"outlined"}
                                                        />}
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Autocomplete
                                                    id={"specialRequest"}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    options={specialRequestTypes}
                                                    getOptionLabel={(option) => option}
                                                    value={specialRequest}
                                                    onChange={(e, newValue) => setSpecialRequest(newValue)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params} label={"Special Request"} variant={"outlined"}
                                                        />
                                                    }
                                                    popupIcon={<ArrowDownIcon />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <TextField id={"remark"} label={"Remark"} variant={"outlined"}
                                                    {...register("remark")}
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    autoComplete={"off"}
                                                    error={!!formErrors?.remark}
                                                    helperText={formErrors?.remark?.message}
                                                    onChange={(event) => validateText(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <FormControlLabel
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    variant={"subtitle"}
                                                    labelPlacement="start"
                                                    control={<Switch
                                                        {...register("selfCollection")}
                                                        checked={selfCollectionSwitchValue}
                                                        onChange={(e) => handleSelfCollectionChange(e.target.checked)} />}
                                                    label="Self-Collection"
                                                />
                                            </Grid>                                            
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <FormControlLabel
                                                    disabled={menuUpdate || approved || (!menuUpdate && currentBooking && eventBookingId && !auth.checkModulePrivilege(PrivilegeModules.fnb_event_booking, PrivilegeActions.edit))}
                                                    variant={"subtitle"}
                                                    labelPlacement="start"
                                                    control={<Switch
                                                        {...register("enabled")}
                                                        checked={enabledSwitchValue}
                                                        onChange={(e) => setEnabledSwitchValue(e.target.checked)} />}
                                                    label="Enable"
                                                />
                                            </Grid>
                                            {
                                                (currentBooking && !!selectedMenu) &&
                                                <>
                                                    <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                                                        <TextField id={"fnbEventMenuSurveyUrl"} label={"Survey URL"} variant={"outlined"}
                                                            {...register("fnbEventMenuSurveyUrl")}
                                                            InputProps={{
                                                                readOnly: true,
                                                                onFocus: handleSurveyUrlFocus,
                                                            }}                                                        
                                                            autoComplete={"off"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                                                        <TextField id={"fnbEventMenuBillSettlementUrl"} label={"Bill URL"} variant={"outlined"}
                                                            {...register("fnbEventMenuBillSettlementUrl")}
                                                            InputProps={{
                                                                readOnly: true,
                                                                onFocus: handleSurveyUrlFocus,
                                                            }}                                                        
                                                            autoComplete={"off"}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        }
    </>);
};

export default withRouter(FnbEventBookingCard);