import {
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Clear as ClearIcon, Event as CalendarIcon } from "@mui/icons-material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { validateText } from "../../../../helper/validate-textfield";
import {
  isAwarded,
  isNew,
  isRejected,
  isStep_0_5,
  isStep_1_5,
  isSubmittedForApproval,
} from "../../helper/pr-config";
import { PR_PANELS } from "../../helper/panels";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";
import DateController from "../../../../components/DateController";
import { useForm } from "react-hook-form";

const WorkflowGeneral = (props) => {
  const { control } = useForm({ mode: "all" });

  const isEditable = () => {
    return (
      (isRejected(props.projectRequest?.status) ||
        isNew(props.projectRequest?.status) ||
        isStep_0_5(props.step) ||
        (isStep_1_5(props.step) &&
          !isSubmittedForApproval(props.projectRequest))) &&
      props.hasEditPrivilege
    );
  };

  const onChangeEstimatedContractStart = (newValue) => {
    props.setValue("estimatedContractStart", newValue);
    props?.setEstimatedContractStart(newValue);
  };

  const onChangeTendencyDueDate = (newValue) => {
    props.setValue("tendencyDueDate", newValue);
    props?.setTendencyDueDate(newValue);
  };

  const onChangeEstimatedContractCompletion = (newValue) => {
    props.setValue("estimatedContractCompletion", newValue);
    props?.setEstimatedContractCompletion(newValue);
  };

  const onChangeCompany = (newValue) => {
    props?.setSelectedCompany(newValue);
  };

  return (
    <>
      <Grid container className={"form"} id={PR_PANELS.GENERAL}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Typography variant={"h2"}>General</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {props?.projectRequest && (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      id={"referenceNo"}
                      label={"Reference No."}
                      variant={"outlined"}
                      {...props.register("referenceNo")}
                      disabled={true}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <Autocomplete
                    id={"company"}
                    disabled={props?.projectRequest}
                    options={props?.companyList.filter((item) => {
                      if (!props?.projectRequest) {
                        return item.status == true;
                      } else {
                        return item;
                      }
                    })}
                    getOptionLabel={(company) => `${company.name}`}
                    value={props?.selectedCompany}
                    onChange={(e, newValue) => onChangeCompany(newValue)}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Company"}
                        variant={"outlined"}
                      />
                    )}
                    popupIcon={<ArrowDownIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    label={"Tendency Due Date"}
                    name={"tendencyDueDate"}
                    required={false}
                    value={
                      props?.tendencyDueDate
                        ? new Date(props?.tendencyDueDate)
                        : null
                    }
                    onChange={onChangeTendencyDueDate}
                    disabled={!isEditable()}
                  />

                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker
                      label="Tendency Due Date"
                      variant={"inline"}
                      inputVariant={"outlined"}
                      closeOnSelect={true}
                      hideTabs={true}
                      showToolbar={false}
                      value={
                        props?.tendencyDueDate
                          ? new Date(props?.tendencyDueDate)
                          : null
                      }
                      format={"dd/MM/yyyy"}
                      views={["day"]}
                      inputFormat={"dd/MM/yyyy"}
                      ignoreInvalidInputs={true}
                      disabled={!isEditable()}
                      mask="__/__/____"
                      onChange={(newValue) => onChangeTendencyDueDate(newValue)}
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                        endAdornment: props?.tendencyDueDate && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => onChangeTendencyDueDate(null)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...props.register("tendencyDueDate", {
                            required: {
                              value: false,
                              message: "Tendency Due Date cannot be blank.",
                            },
                          })}
                          {...params}
                          error={!!props.formErrors?.tendencyDueDate}
                          helperText={
                            props.formErrors?.tendencyDueDate?.message
                          }
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD/MM/YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"customer"}
                    label={"Customer"}
                    variant={"outlined"}
                    {...props.register("customer", {
                      required: {
                        value: true,
                        message: "Customer cannot be blank.",
                      },
                    })}
                    disabled={!isEditable()}
                    autoComplete={"off"}
                    error={!!props.formErrors?.customer}
                    helperText={props.formErrors?.customer?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"uen-no"}
                    label={"UEN No."}
                    variant={"outlined"}
                    {...props.register("uen", {
                      required: {
                        value: true,
                        message: "UEN No. cannot be blank.",
                      },
                    })}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.uen}
                    helperText={props.formErrors?.uen?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"location"}
                    label={"Project Address"}
                    variant={"outlined"}
                    {...props.register("location", {
                      required: {
                        value: true,
                        message: "Project Address cannot be blank.",
                      },
                    })}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.location}
                    helperText={props.formErrors?.location?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"credit-limit"}
                    label={"Contract Limit"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("creditLimit")}
                    disabled={!isEditable()}
                    autoComplete={"off"}
                    onChange={(event) => validateText(event)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"deposit"}
                    label={"Deposit"}
                    type={"number"}
                    variant={"outlined"}
                    {...props.register("deposit")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.deposit}
                    helperText={props.formErrors?.deposit?.message}
                    onChange={(event) => validateText(event)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"validity"}
                    label={"Validity"}
                    variant={"outlined"}
                    {...props.register("validity")}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"creditTerm"}
                    label={"Credit Term"}
                    variant={"outlined"}
                    {...props.register("creditTerm", {
                      required: {
                        value: isEditable(),
                        message: "Credit Term cannot be blank.",
                      },
                    })}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.creditTerm}
                    helperText={props.formErrors?.creditTerm?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    label={"Estimated Contract Start"}
                    name={"estimatedContractStart"}
                    required={true}
                    value={
                      props?.estimatedContractStart
                        ? new Date(props?.estimatedContractStart)
                        : null
                    }
                    onChange={onChangeEstimatedContractStart}
                    disabled={!isEditable()}
                  />

                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker
                      label="Estimated Contract Start"
                      variant={"inline"}
                      inputVariant={"outlined"}
                      closeOnSelect={true}
                      hideTabs={true}
                      showToolbar={false}
                      value={
                        props?.estimatedContractStart
                          ? new Date(props?.estimatedContractStart)
                          : null
                      }
                      format={"dd/MM/yyyy"}
                      views={["day"]}
                      inputFormat={"dd/MM/yyyy"}
                      ignoreInvalidInputs={true}
                      disabled={!isEditable()}
                      mask="__/__/____"
                      onChange={(newValue) =>
                        onChangeEstimatedContractStart(newValue)
                      }
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                        endAdornment: props?.estimatedContractStart && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                onChangeEstimatedContractStart(null)
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...props.register("estimatedContractStart", {
                            required: {
                              value: true,
                              message:
                                "Estimated Contract Start cannot be blank.",
                            },
                          })}
                          {...params}
                          error={!!props.formErrors?.estimatedContractStart}
                          helperText={
                            props.formErrors?.estimatedContractStart?.message
                          }
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD/MM/YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    label={"Estimated Contract Completion"}
                    name={"estimatedContractCompletion"}
                    required={true}
                    value={
                      props?.estimatedContractCompletion
                        ? new Date(props?.estimatedContractCompletion)
                        : null
                    }
                    onChange={onChangeEstimatedContractCompletion}
                    disabled={!isEditable()}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker
                      label="Estimated Contract Completion"
                      variant={"inline"}
                      inputVariant={"outlined"}
                      closeOnSelect={true}
                      hideTabs={true}
                      showToolbar={false}
                      value={
                        props?.estimatedContractCompletion
                          ? new Date(props?.estimatedContractCompletion)
                          : null
                      }
                      format={"dd/MM/yyyy"}
                      views={["day"]}
                      inputFormat={"dd/MM/yyyy"}
                      ignoreInvalidInputs={true}
                      mask="__/__/____"
                      onChange={(newValue) =>
                        onChangeEstimatedContractCompletion(newValue)
                      }
                      disabled={!isEditable()}
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                        endAdornment: props?.estimatedContractCompletion && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                onChangeEstimatedContractCompletion(null)
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...props.register("estimatedContractCompletion", {
                            required: {
                              value: true,
                              message:
                                "Estimated Contract Completion cannot be blank.",
                            },
                          })}
                          {...params}
                          error={
                            !!props.formErrors?.estimatedContractCompletion
                          }
                          helperText={
                            props.formErrors?.estimatedContractCompletion
                              ?.message
                          }
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD/MM/YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                </Grid>
                {props?.projectRequest &&
                  isAwarded(props?.projectRequest?.status) && (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <DateController
                          control={control}
                          label={"Contract Start"}
                          name={"contractStart"}
                          required={false}
                          value={
                            props?.contractStart
                              ? new Date(props?.contractStart)
                              : null
                          }
                          onChange={(newValue) =>
                            props?.setContractStart(newValue)
                          }
                          disabled={!props.hasEditPrivilege}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDateTimePicker
                            label="Contract Start"
                            variant={"inline"}
                            inputVariant={"outlined"}
                            closeOnSelect={true}
                            hideTabs={true}
                            showToolbar={false}
                            value={
                              props?.contractStart
                                ? new Date(props?.contractStart)
                                : null
                            }
                            format={"dd/MM/yyyy"}
                            views={["day"]}
                            inputFormat={"dd/MM/yyyy"}
                            disabled={!props.hasEditPrivilege}
                            mask="__/__/____"
                            onChange={(newValue) =>
                              props?.setContractStart(newValue)
                            }
                            InputAdornmentProps={{ position: "start" }}
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CalendarIcon />
                                </InputAdornment>
                              ),
                              endAdornment: props?.contractStart && (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      props?.setContractStart(null)
                                    }
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD/MM/YYYY",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider> */}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <DateController
                          control={control}
                          label={"Contract Completion"}
                          name={"contractCompletion"}
                          required={false}
                          value={
                            props?.contractCompletion
                              ? new Date(props?.contractCompletion)
                              : null
                          }
                          onChange={(newValue) =>
                            props?.setContractCompletion(newValue)
                          }
                          disabled={!props.hasEditPrivilege}
                        />

                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDateTimePicker
                            label="Contract Completion"
                            variant={"inline"}
                            inputVariant={"outlined"}
                            closeOnSelect={true}
                            hideTabs={true}
                            showToolbar={false}
                            value={
                              props?.contractCompletion
                                ? new Date(props?.contractCompletion)
                                : null
                            }
                            format={"dd/MM/yyyy"}
                            views={["day"]}
                            inputFormat={"dd/MM/yyyy"}
                            mask="__/__/____"
                            onChange={(newValue) =>
                              props?.setContractCompletion(newValue)
                            }
                            disabled={!props.hasEditPrivilege}
                            InputAdornmentProps={{ position: "start" }}
                            InputProps={{
                              autoComplete: "off",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CalendarIcon />
                                </InputAdornment>
                              ),
                              endAdornment: props?.contractCompletion && (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      props?.setContractCompletion(null)
                                    }
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD/MM/YYYY",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider> */}
                      </Grid>
                    </>
                  )}

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"projectTitle"}
                    label={"Project Title"}
                    variant={"outlined"}
                    {...props.register("projectTitle", {
                      required: {
                        value: isEditable() && props.version > 1,
                        message: "Project Title cannot be blank.",
                      },
                    })}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.projectTitle}
                    helperText={props.formErrors?.projectTitle?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <TextField
                    id={"internalReferenceNo"}
                    label={"Internal Reference No."}
                    type={"text"}
                    variant={"outlined"}
                    {...props.register("internalReferenceNo", {
                      required: {
                        value: isEditable() && props.version > 1,
                        message: "Internal Reference No cannot be blank.",
                      },
                    })}
                    autoComplete={"off"}
                    disabled={!isEditable()}
                    error={!!props.formErrors?.internalReferenceNo}
                    helperText={props.formErrors?.internalReferenceNo?.message}
                    onChange={(event) => validateText(event)}
                  />
                </Grid>

                {props?.projectRequest && props?.projectRequest?.project && (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      id={"project"}
                      label={"Project ID"}
                      type={"text"}
                      variant={"outlined"}
                      {...props.register("project")}
                      disabled={true}
                      value={
                        `${props?.projectRequest.project?.projectId}` +
                        `-${props?.projectRequest.project?.projectName}`
                      }
                      autoComplete={"off"}
                    />
                  </Grid>
                )}
                {props?.projectRequest &&
                  props?.projectRequest?.projectManager && (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <TextField
                        id={"projectManager"}
                        label={"Project Manager"}
                        variant={"outlined"}
                        {...props.register("projectManager")}
                        disabled={true}
                      />
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkflowGeneral;
