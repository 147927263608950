import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  Search as SearchIcon,
  KeyboardArrowDown as ArrowDownIcon,
  PlaylistPlay as FilterIcon,
  ArrowBack as BackIcon,
  Clear as ClearIcon,
  Event as CalendarIcon,
} from "@mui/icons-material";
import React, { useEffect, useState, useMemo } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { useUserState } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import { claimsTableColumnConfig } from "./data-table/claims-table-column-config";
import DataTable from "react-data-table-component";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import moment from "moment";
import MobileClaimsFilters from "./components/Mobile/MobileClaimsFilters";
import Swal from "sweetalert2";
import ClaimInvoiceInfoDrawer from "./components/Drawer/ClaimInvoiceInfoDrawer";
import downloadCSV from "../../helper/download-csv";
import {
  INSTALLATION_CLAIMS_STATUS,
  ENABLE_OPTIONS,
} from "../../data/constants";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import * as _ from "lodash";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from "../../components/DateFilter";
import InsClaimOtherDeductionPopup from "./components/InsClaimOtherDeductionPopup/ins-claim-other-deduction-popup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ManageInstallationClaims = (props) => {
  const classes = useStyles();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  // page authorization
  const { auth } = useRoleAuthorization();

  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { width } = useWindowDimensions();

  const [claimList, setClaimList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false);

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  // filter states
  const [textFilter, setTextFilter] = useState("");
  const [teamFilter, setTeamFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [invoicePostedFilter, setInvoicePostedFilter] = useState(null);
  const [downloadedFilter, setDownloadedFilter] = useState(null);
  const [claimDateFrom, setClaimDateFrom] = useState(null);
  const [claimDateTo, setClaimDateTo] = useState(null);
  const [calculatedFilter, setCalculatedFilter] = useState(null);

  const [message, setMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [claimsToBeApproved, setClaimsToBeApproved] = useState([]); // claims remaining for approval
  const [lastApproval, setLastApproval] = useState(null); // claims remaining for approval
  const [loadMobileFilters, setLoadMobileFilters] = useState(false);
  const { userInfo } = useUserState();

  const postedList = ENABLE_OPTIONS;
  const statusList = Object.values(INSTALLATION_CLAIMS_STATUS);

  // set right bar for small devices
  const smallDeviceWidth = 1200;
  const mobileFilterProps = {
    teamList,
    statusList,
    postedList,
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    setTeamFilter,
    setStatusFilter,
    setInvoicePostedFilter,
    setClaimDateFrom,
    setClaimDateTo,
    downloadedFilter,
    setDownloadedFilter,
    calculatedFilter,
    setCalculatedFilter,
    allowedSeeMoreColumns: auth.checkModulePrivilege(
      PrivilegeModules.installation_claim,
      PrivilegeActions.see_more_columns,
    ),
  };

  const [openDiaOtherDeduction, setOpenDiaOtherDeduction] = useState(false);
  const [wsri, setWsri] = useState(0);
  const [vehicle, setVehicle] = useState(0);
  const [diesel, setDiesel] = useState(0);
  const [mandaysOfGC, setMandaysOfGC] = useState(0);
  const [salary, setSalary] = useState(0);
  const [additionalDeduction, setAdditionalDeduction] = useState(0);
  const [otherDeductionRemark, setOtherDeductionRemark] = useState("");

  useEffect(() => {
    document.title = "Installation Claim List";
    loadSubCons();
  }, []);

  useMemo(() => {
    if (auth.isPrivilegeDataLoaded()) {
      if (
        !auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.view_list,
        )
      ) {
        props.history.push("/app/dashboard");
      }

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_status_pending,
          false,
        )
      ) {
        setStatusFilter(INSTALLATION_CLAIMS_STATUS.PENDING);
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_status_approved,
          false,
        )
      ) {
        setStatusFilter(INSTALLATION_CLAIMS_STATUS.APPROVED);
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_status_cancelled,
          false,
        )
      ) {
        setStatusFilter(INSTALLATION_CLAIMS_STATUS.CANCELLED);
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_status_paid,
          false,
        )
      ) {
        setStatusFilter(INSTALLATION_CLAIMS_STATUS.PAID);
      }

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_posted_yes,
          false,
        )
      ) {
        setInvoicePostedFilter("Yes");
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_posted_no,
          false,
        )
      ) {
        setInvoicePostedFilter("No");
      }

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_downloaded_yes,
          false,
        )
      ) {
        setDownloadedFilter("Yes");
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.installation_claim,
          PrivilegeActions.filter_downloaded_no,
          false,
        )
      ) {
        setDownloadedFilter("No");
      }
    }
  }, [userInfo]);

  // filter
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useEffect(() => {
    setRefresh(true);
  }, [
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    downloadedFilter,
    calculatedFilter,
  ]);

  useEffect(() => {
    if (width <= smallDeviceWidth && loadMobileFilters) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileClaimsFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [
    teamFilter,
    statusFilter,
    invoicePostedFilter,
    claimDateFrom,
    claimDateTo,
    claimList,
    downloadedFilter,
    calculatedFilter,
    width,
    loadMobileFilters,
  ]);

  // show feedback messages
  useEffect(() => {
    if (message) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
      });
      setMessage("");
    }
  }, [message, responseData]);

  useEffect(() => {
    if (warningMessage) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: warningMessage,
      });
      setWarningMessage("");
    }
  }, [warningMessage, responseData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // set claims remaining for approval
  useEffect(() => {
    const list = claimList.filter(
      (c) =>
        c.status === INSTALLATION_CLAIMS_STATUS.PENDING &&
        c.invoicePosted === "Yes",
    );
    setClaimsToBeApproved(list);
  }, [claimList]);

  // show message after approval and load next claim to approve
  useEffect(() => {
    if (lastApproval) {
      Swal.fire({
        title: `Success`,
        text: `Installation claim for invoice ${lastApproval.invoiceNo} has been approved.`,
        icon: "success",
        showCancelButton: claimsToBeApproved.length > 0,
        confirmButtonText: "Ok",
        cancelButtonText:
          claimsToBeApproved.length > 0 ? "Next Installation Claim" : undefined,
      }).then((approvalSuccessResult) => {
        if (approvalSuccessResult.isConfirmed) {
          toggleRightbar(layoutDispatch);
        } else {
          const nextClaim = claimsToBeApproved[0]; // next one in pending list
          if (nextClaim) {
            loadRightSideContent(nextClaim, true, true);
          }
        }
      });
    }
  }, [lastApproval]);

  function loadRightSideContent(claim, backButton, showBackDropInLargeScreen) {
    const size = width <= smallDeviceWidth ? "xs" : "lg";
    setRighbarContent(
      layoutDispatch,
      [
        <ClaimInvoiceInfoDrawer
          key={"rb-body"}
          claim={claim}
          invoice={claim.invoice}
          onApproveSingle={onApproveSingle}
          calculate={calculate}
          checkPosted={checkPosted}
          revertClaim={revertClaim}
          hasPermissionToApprove={auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.approve,
          )}
          deleteClaim={deleteClaim}
          hasPermissionToDelete={auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.delete,
          )}
          hasPermissionToRevert={auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.revert_pks_claim,
          )}
        />,
      ],
      { size, backButton, showBackDropInLargeScreen },
    );
  }

  // set columns in data table
  const columns = claimsTableColumnConfig(
    {
      allowedSeeMoreColumns: auth.checkModulePrivilege(
        PrivilegeModules.installation_claim,
        PrivilegeActions.see_more_columns,
      ),
      allowedViewDetail: auth.checkModulePrivilege(
        PrivilegeModules.installation_claim,
        PrivilegeActions.view_detail,
      ),
      allowEdit: auth.checkModulePrivilege(
        PrivilegeModules.installation_claim,
        PrivilegeActions.edit,
      ),
    },
    handleInvoiceNoClick,
    handleEditRow,
  );

  function filter() {
    let abortController = new AbortController();

    // set list filters
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const claims = response.data?.claimList || [];
        setClaimList(claims);
        setCollectionSize(response.data?.total);
        return () => {
          abortController.abort();
          return claims;
        };
      },
    );

    clearOtherDeduction();
  }

  async function calculate(claim) {
    const response = await sendRequest(
      `/v1/installation-claim/calculate/${claim._id.toString()}`,
      "PUT",
      {},
    );
    if (response?.status === 200 && response?.data?.claim) {
      setMessage(`Installation claim amount for invoice 
            ${response?.data?.claim?.invoiceNo} has been calculated.`);
      setRefresh(true);
      const updatedClaim = response?.data?.claim;
      updatedClaim.matrix = claim.matrix;
      loadRightSideContent(updatedClaim, true, false); // load new claim after re-calculation
    }
  }

  async function checkPosted(claim) {
    if (claim) {
      const response = await sendRequest(
        `/v1/installation-claim/check-posted`,
        "POST",
        { invoiceNo: claim.invoiceNo, team: claim.team },
      );
      if (response?.status === 200 && response?.data) {
        if (response?.data.posted) {
          claim.invoicePosted = "Yes";
          setMessage(
            `Posted status for invoice ${claim.invoiceNo} has been updated.`,
          );
        } else {
          setWarningMessage(`${claim.invoiceNo} is not posted in Navision.`);
        }
        setRefresh(true);
        loadRightSideContent(claim, true, false);
      }
    }
  }

  async function revertClaim(claim) {
    if (claim) {
      const result = await Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to revert the installation claim for invoice ${claim.invoiceNo}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      });
      if (result?.isConfirmed) {
        const response = await sendRequest(
          `/v1/installation-claim/revert-pks-claim/${claim.invoiceNo}`,
          "PUT",
        );
        if (response?.status === 200 && response?.data) {
          claim.status = response?.data?.installationClaim?.status;
          setMessage(
            `Installation claim for invoice ${claim.invoiceNo} has been reverted.`,
          );
        }
        setRefresh(true);
        loadRightSideContent(claim, true, false);
      }
    }
  }

  function deleteClaim(claim) {
    if (claim) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to delete the installation claim for invoice ${claim.invoiceNo}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await sendRequest(
            `/v1/installation-claim/${claim._id}`,
            "DELETE",
          );
          if (response?.status === 200 && response?.data?.deleted) {
            setMessage(
              `Installation claim for invoice ${claim.invoiceNo} has been deleted.`,
            );
            setRefresh(true);
            toggleRightbar(layoutDispatch);
          }
        }
      });
    }
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      team: teamFilter ? teamFilter.username : undefined,
      status: statusFilter ?? undefined,
      from: claimDateFrom ? getFromDate(claimDateFrom) : undefined,
      to: claimDateTo ? getToDate(claimDateTo) : undefined,
      posted: invoicePostedFilter ?? undefined,
      downloaded: downloadedFilter ?? undefined,
      calculated: calculatedFilter ?? undefined,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/installation-claim/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  // populate sub-con list
  function loadSubCons() {
    let abortController = new AbortController();
    sendRequest(
      `/v1/user/get-list-by-role/option_ins_claim_team`,
      "GET",
      {},
    ).then((response) => {
      const list = response.data?.users || [];
      setTeamList(list.filter((i) => i.status));

      return () => {
        abortController.abort();
      };
    });
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleEditRow(claim) {
    props.history.push(`edit/${claim.invoiceNo}/${claim.team}`);
  }

  //  when click on invoice no in the list
  function handleInvoiceNoClick(claim) {
    // if claim approval role, right drawer will open
    if (
      auth.checkModulePrivilege(
        PrivilegeModules.installation_claim,
        PrivilegeActions.view_detail,
      )
    ) {
      setLoadMobileFilters(false);
      loadRightSideContent(claim, true, false);
      if (!layoutState.isRightbarOpened) {
        toggleRightbar(layoutDispatch);
      }
    }
  }

  // remove last approved claim
  function removeRecentlyApprovedClaim(invoiceNo) {
    const list = [...claimsToBeApproved];
    const index = list.findIndex((c) => c.invoiceNo === invoiceNo);
    if (index !== -1) {
      list.splice(index, 1);
    }
    setClaimsToBeApproved([...list]);
  }

  // when approve all button clicked
  async function onApproveAll() {
    if (claimList && claimList.length > 0) {
      const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
      const claims = response?.data?.claimList || [];
      const pendingList = claims.filter(
        (c) =>
          c.status === INSTALLATION_CLAIMS_STATUS.PENDING &&
          c.invoicePosted === "Yes" &&
          c.calculated === "Yes" &&
          c.orderBalance === 0,
      );
      if (pendingList.length > 0) {
        Swal.fire({
          title: `Confirmation`,
          text: `Proceeding will approve all the pending installation claim(s) with posted invoice. Are you sure you want to proceed?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const result = await sendRequest(
              `/v1/installation-claim/approve`,
              "POST",
              { invoiceNos: pendingList.map((c) => c.invoiceNo) },
            );

            if (result?.status === 200 && result?.data?.updated) {
              setMessage(
                `All the pending installation claim(s) with posted invoice has been approved.`,
              );
              setRefresh(true);
            }
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No pending installation claims with posted invoice found.",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No pending installation claims with posted invoice found.",
      });
    }
  }

  function isEligibleToDownloadReport() {
    return (
      teamFilter?.username &&
      claimDateFrom &&
      claimDateTo &&
      statusFilter === INSTALLATION_CLAIMS_STATUS.APPROVED &&
      downloadedFilter === "No" &&
      claimList.length > 0
    );
  }

  // when approve single claim from the right drawer
  function onApproveSingle(claim) {
    if (
      claim &&
      claim.status === INSTALLATION_CLAIMS_STATUS.PENDING &&
      claim.invoicePosted === "Yes" &&
      claim.calculated === "Yes" &&
      claim.orderBalance === 0
    ) {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to approve the installation claim for invoice ${claim.invoiceNo}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await sendRequest(
            `/v1/installation-claim/approve`,
            "POST",
            { invoiceNos: [claim.invoiceNo] },
          );

          if (result?.status === 200 && result?.data?.updated) {
            claim.status = INSTALLATION_CLAIMS_STATUS.APPROVED;
            setMessage(
              `Installation claim for invoice ${claim.invoiceNo} has been approved.`,
            );
            setRefresh(true);
            removeRecentlyApprovedClaim(claim.invoiceNo); // remove & update pending list
            setLastApproval(claim); // show message & set load next claim for approval
            loadRightSideContent(claim, true, false);
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Unable to approve the installation claim for invoice ${claim.invoiceNo}`,
      });
    }
  }

  // generated and download csv report
  async function downloadReport(approved_only) {
    const csvData = [];
    // only APPROVED and not downloaded records will be in the report
    const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
    let claims = response?.data?.claimList || [];

    if (approved_only) {
      claims = claims.filter(
        (c) =>
          c.downloaded === "No" &&
          c.status === INSTALLATION_CLAIMS_STATUS.APPROVED,
      );
    }

    if (claims.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "No data to export.",
      });
      return;
    }
    const row = {
      claimNo: "",
      installer: "",
      totalClaimAmount: "",
      claimDate: "",
      approvedDate: "",
      orderBalance: "",
      customerBalance: "",
      customerNo: "",
      customerName: "",
      invoiceNo: "",
      invoiceDate: "",
      salesOrderNo: "",
      salesOrderDate: "",
      pickOrderNo: "",
      deliveryDate: "",
      salesPerson: "",
      teamNo: "",
      teamName: "",
      contractNo: "",
      itemNo: "",
      descriptionModel: "",
      qty: "",
      unitPrice: "",
      discount: "",
      total: "",
      claimAmount: "",
    };
    for (const claim of claims) {
      // claim record
      csvData.push({
        claimNo: claim.invoiceNo,
        installer: claim.installer?.username,
        totalClaimAmount: claim.totalClaimAmount
          ? claim.totalClaimAmount.toFixed(2)
          : "0.00",
        claimDate: claim.claimDate
          ? moment(claim.claimDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        approvedDate: claim.approvedDate
          ? moment(claim.approvedDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        orderBalance: claim.orderBalance
          ? claim.orderBalance.toFixed(2)
          : "0.00",
        customerBalance: claim.customerBalance
          ? claim.customerBalance.toFixed(2)
          : "0.00",
        customerNo: claim.invoice?.customerNo,
        customerName: claim.invoice?.billToName,
        invoiceNo: claim.invoiceNo,
        invoiceDate: claim.invoice?.invoiceDate
          ? moment(claim.invoice?.invoiceDate).format("DD/MM/YYYY")
          : "",
        salesOrderNo: claim.invoice?.orderNo,
        salesOrderDate: claim?.claimDate
          ? moment(claim?.claimDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        pickOrderNo: claim.invoice?.pickOrderNo,
        deliveryDate: claim.invoice?.deliveryDate
          ? moment(claim.invoice?.deliveryDate).format("DD/MM/YYYY")
          : "",
        salesPerson: claim.invoice?.salesPerson,
        teamNo: claim.team,
        teamName: claim.teamName,
        contractNo: claim.invoice?.contractNo,
        itemNo: "",
        descriptionModel: "",
        qty: "",
        unitPrice: "",
        discount: "",
        total: "",
        claimAmount: "",
      });
      // invoice lines
      if (claim.invoice?.invoiceLines) {
        for (const item of claim.invoice.invoiceLines) {
          csvData.push({
            ...row,
            ...{
              itemNo: item.no,
              descriptionModel: item.description,
              qty: item.quantity,
              unitPrice: item.unitPrice ? item.unitPrice.toFixed(2) : "0.00",
              discount: item.lineDiscountAmount
                ? item.lineDiscountAmount.toFixed(2)
                : "0.00",
              total: item.lineTotalAmount
                ? item.lineTotalAmount.toFixed(2)
                : "0.00",
              claimAmount: item.claimAmount
                ? item.claimAmount.toFixed(2)
                : "0.00",
            },
          });
        }
      }
      csvData.push({});
    }
    csvData.push({
      ...row,
      ...{
        total: "Total Claim Amount",
        claimAmount: _.sum(claims.map((c) => c.totalClaimAmount)),
      },
    });
    const today = moment().format("YYYYMMDD_HHmmss");
    const filename = `INSTALLATION_CLAIM-REPORT-${today}.csv`;
    const downloaded = downloadCSV(csvData, filename);
    if (downloaded && approved_only) {
      // update the list as downloaded
      sendRequest(
        `/v1/installation-claim/downloaded-status`,
        "POST",
        claims.map((claim) => claim._id),
      ).then((result) => {
        if (result.data?.updated) {
          setRefresh(true);
        }
      });
    }
  }

  // open mobile filters drawer
  function openMobileFilters() {
    setLoadMobileFilters(true);
    toggleRightbar(layoutDispatch);
  }

  async function refreshClaimData() {
    const response = await sendRequest(
      `/v1/installation-claim/refresh-data`,
      "POST",
    );
    if (response?.status === 200 && response?.data) {
      if (response?.data.completed) {
        setMessage(`All pending installation claim(s) has been refreshed.`);
      }
      setRefresh(true);
    }
  }

  function generateReport(filters, otherDeductions) {
    return sendRequest(
      `/v1/installation-claim-report/generate-report`,
      "POST",
      { filterSet: filters, otherDeductions: otherDeductions },
    );
  }

  function onDownloadApprovedReportClick() {
    if (claimList.length == 0) {
      return;
    }

    setOpenDiaOtherDeduction(true);
  }

  async function downloadApprovedReport() {
    if (claimList.length == 0) {
      return;
    }

    setOpenDiaOtherDeduction(false);

    const filters = {
      ...getFilterValues(),
      teamName: teamFilter ? teamFilter.name : undefined,
      customerNo: teamFilter ? teamFilter?.customerNo : undefined,
    };

    const otherDeductions = {
      wsri: wsri,
      vehicle: vehicle,
      diesel: diesel,
      mandaysOfGC: mandaysOfGC,
      salary: salary,
      additionalDeduction: additionalDeduction,
      otherDeductionRemark: otherDeductionRemark,
    };

    const response = await generateReport(filters, otherDeductions);
    if (response?.status === 200 && response?.data?.url) {
      filter();

      const link = document.createElement("a");
      link.setAttribute("href", response?.data?.url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", response?.data?.fileName);
      link.click();
    }
  }

  function clearOtherDeduction() {
    setWsri(0);
    setVehicle(0);
    setDiesel(0);
    setMandaysOfGC(0);
    setSalary(0);
    setAdditionalDeduction(0);
    setOtherDeductionRemark("");
  }

  function closeDiaOtherDeduction() {
    setOpenDiaOtherDeduction(false);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Installation Claim List</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("card")}
            >
              New
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.approve,
          ) && (
            <Button className={"primary"} onClick={() => onApproveAll()}>
              Approve
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.refresh,
          ) && (
            <Button className={"primary"} onClick={() => refreshClaimData()}>
              Refresh
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.installation_claim,
            PrivilegeActions.download_report,
          ) && (
            <Button className={"primary"} onClick={() => downloadReport(false)}>
              Download Report
            </Button>
          )}
          {auth.checkModulePrivilege(
            PrivilegeModules.installation_claim_report,
            PrivilegeActions.download_report_approved_only,
          ) && (
            <Button
              className={"primary"}
              onClick={() => onDownloadApprovedReportClick()}
              disabled={!isEligibleToDownloadReport()}
            >
              Download Report
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container className={"form"}>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={10} lg={2}>
                  <TextField
                    autoComplete={"off"}
                    id={"text-searchbox"}
                    variant={"outlined"}
                    value={textFilter}
                    onChange={(e) => setTextFilter(e.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"start"}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {width >= smallDeviceWidth ? (
                  <>
                    {auth.checkModulePrivilege(
                      PrivilegeModules.installation_claim,
                      PrivilegeActions.show_team_filter,
                    ) && (
                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Autocomplete
                          id={"team-filter"}
                          options={teamList}
                          getOptionLabel={(option) =>
                            `${option.username}-${option.name}`
                          }
                          value={teamFilter}
                          onChange={(e, newValue) => setTeamFilter(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Team"}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<ArrowDownIcon />}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <Autocomplete
                        id={"status-filter"}
                        options={statusList}
                        getOptionLabel={(option) => option}
                        value={statusFilter}
                        onChange={(e, newValue) => setStatusFilter(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Status"}
                            variant={"outlined"}
                          />
                        )}
                        popupIcon={<ArrowDownIcon />}
                      />
                    </Grid>
                    {auth.checkModulePrivilege(
                      PrivilegeModules.installation_claim,
                      PrivilegeActions.see_more_columns,
                    ) && (
                      <>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <Autocomplete
                            id={"posted-filter"}
                            options={postedList}
                            getOptionLabel={(option) => option?.toUpperCase()}
                            value={invoicePostedFilter}
                            onChange={(e, newValue) =>
                              setInvoicePostedFilter(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Posted"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          <Autocomplete
                            id={"downloaded-filter"}
                            options={postedList}
                            getOptionLabel={(option) => option?.toUpperCase()}
                            value={downloadedFilter}
                            onChange={(e, newValue) =>
                              setDownloadedFilter(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Downloaded"}
                                variant={"outlined"}
                              />
                            )}
                            popupIcon={<ArrowDownIcon />}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                          {/* <Autocomplete
                                                id={"calculated-filter"}
                                                options={postedList}
                                                getOptionLabel={(option) => option?.toUpperCase()}
                                                value={calculatedFilter}
                                                onChange={(e, newValue) => setCalculatedFilter(newValue)}
                                                renderInput={(params) =>
                                                    <TextField {...params} label={"Calculated"} variant={"outlined"} />}
                                                popupIcon={<ArrowDownIcon />}
                                            /> */}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <DateFilter
                        label={"Claim Date - From"}
                        value={claimDateFrom}
                        maxDate={claimDateTo}
                        onChange={(newValue) => setClaimDateFrom(newValue)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <DateFilter
                        label={"Claim Date - To"}
                        value={claimDateTo}
                        minDate={claimDateFrom}
                        onChange={(newValue) => setClaimDateTo(newValue)}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => openMobileFilters()}
                      className={classes.drawerToggleBtn}
                    >
                      {layoutState.isRightbarOpened ? (
                        <BackIcon />
                      ) : (
                        <FilterIcon className={classes.toggleRightBarIcon} />
                      )}
                    </IconButton>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <DataTable
                    fixedHeader={true}
                    persistTableHead={true}
                    columns={columns}
                    data={claimList}
                    sortServer
                    onSort={sortByColumn}
                    pagination
                    paginationServer
                    paginationDefaultPage={page}
                    paginationPerPage={pageSize}
                    paginationTotalRows={collectionSize}
                    onChangeRowsPerPage={(count) =>
                      handleRowsPerPageChanged(count)
                    }
                    onChangePage={(page) => handlePageChanged(page)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        open={openDiaOtherDeduction}
        maxWidth="sm"
        onClose={closeDiaOtherDeduction}
      >
        <DialogTitle component={"div"}>
          <Grid item>
            <Typography variant={"h2"}>Other Deductions</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <InsClaimOtherDeductionPopup
            wsri={wsri}
            setWsri={setWsri}
            vehicle={vehicle}
            setVehicle={setVehicle}
            diesel={diesel}
            setDiesel={setDiesel}
            mandaysOfGC={mandaysOfGC}
            setMandaysOfGC={setMandaysOfGC}
            salary={salary}
            setSalary={setSalary}
            additionalDeduction={additionalDeduction}
            setAdditionalDeduction={setAdditionalDeduction}
            otherDeductionRemark={otherDeductionRemark}
            setOtherDeductionRemark={setOtherDeductionRemark}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="btnProceed"
            onClick={() => downloadApprovedReport()}
            color="primary"
          >
            Proceed
          </Button>
          <Button
            id="btnCancel"
            onClick={closeDiaOtherDeduction}
            color="primary"
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(ManageInstallationClaims);
