import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormContext } from "react-hook-form";
import moment from "moment/moment";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

const CUTOFF_HOUR = 20;

export function FnbSupplierOrderCardComponent({
  prerequisites,
  supplierList,
  readOnly,
}) {
  const storeOptions = useMemo(() => prerequisites.storesList, [prerequisites]);
  const supplierOptions = useMemo(() => supplierList, [supplierList]);
  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const supplierCode = watch("supplier.vendorCode");
  const storeCode = watch("deliveryTo.storeCode");
  const expectedDateStr = watch("deliveryDate");

  const selectedStoreOption = useMemo(
    () => storeOptions.find((s) => s.storeCode === storeCode) || null,
    [storeCode, storeOptions],
  );

  const selectedSupplier = useMemo(
    () => supplierOptions.find((s) => s.vendorCode === supplierCode) || null,
    [supplierCode, supplierOptions],
  );

  const minDate = useMemo(() => {
    const current = moment();
    if (current.get("hours") >= CUTOFF_HOUR) {
      return current.add(1, "d").startOf("day");
    }
    return current;
  }, []);

  return (
    <Grid container className={"form"}>
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12}>
            <Typography variant={"h2"}>General</Typography>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disabled={readOnly}
              id={"order-destination-store"}
              options={storeOptions}
              getOptionLabel={(storeOption) =>
                `${storeOption.storeName.toUpperCase()}-${storeOption.storeCode.toUpperCase()}`
              }
              isOptionEqualToValue={(option, value) =>
                option.storeCode === value.storeCode
              }
              value={selectedStoreOption}
              onChange={(e, newValue) => {
                setValue("deliveryTo.storeCode", newValue?.storeCode);
                setValue("supplier.vendorCode", null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Store"}
                  variant={"outlined"}
                  helperText={
                    errors?.deliveryTo?.storeCode &&
                    errors?.deliveryTo?.storeCode?.message
                  }
                  error={!!errors?.deliveryTo?.storeCode}
                />
              )}
              popupIcon={<ArrowDownIcon />}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disabled={readOnly}
              id={"order-supplier"}
              options={supplierOptions}
              getOptionLabel={(supplier) =>
                `${supplier.name.toUpperCase()}-${supplier.vendorCode.toUpperCase()}`
              }
              value={selectedSupplier}
              onChange={(e, newValue) => {
                setValue("supplier.vendorCode", newValue?.vendorCode);
                setValue("items", []);
              }}
              noOptionsText={"No supliers for the selected store."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Supplier"}
                  variant={"outlined"}
                  helperText={
                    errors?.supplier?.vendorCode &&
                    errors?.supplier?.vendorCode?.message
                  }
                  error={!!errors?.supplier?.vendorCode}
                />
              )}
              popupIcon={<ArrowDownIcon />}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={readOnly}
              variant={"outlined"}
              label={"Contact Name"}
              helperText={
                errors["contactName"] && "Contact Name cannot be blank."
              }
              error={!!errors["contactName"]}
              {...register("contactName")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={readOnly}
              variant={"outlined"}
              label={"Contact No."}
              helperText={
                errors["contactNumber"] && "Contact No. cannot be blank."
              }
              error={!!errors["contactNumber"]}
              {...register("contactNumber")}
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disabled={readOnly}
                label="Expected Delivery Date"
                format="DD/MM/YYYY"
                disablePast={true}
                minDate={readOnly ? null : minDate}
                value={
                  moment.isMoment(expectedDateStr)
                    ? expectedDateStr
                    : moment(expectedDateStr)
                }
                onChange={(moment) => {
                  setValue("deliveryDate", moment.startOf("day").toDate());
                }}
                helperText={
                  errors["deliveryDate"] &&
                  "Expected Delivery Date cannot be blank."
                }
                error={!!errors["deliveryDate"]}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={readOnly}
              label={"Order Remarks"}
              multiline={true}
              variant={"outlined"}
              {...register("remark")}
              helperText={errors["remark"] && errors["remark"].message}
              error={!!errors["remark"]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
