import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  FormControlLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useStoreState } from "../../../../context/StoreContext";
import moment from "moment";
import { KeyboardArrowDown } from "@mui/icons-material";
import { validateText } from "../../../../helper/validate-textfield";
import {
  PrivilegeActions,
  PrivilegeModules,
} from "../../../../data/privileges.enum";
import { useRoleAuthorization } from "../../../../hooks/useRoleAuthorization";
import { FormProvider } from "react-hook-form";
import TextFieldController from "../../../../components/TextFieldController";
import AutocompleteController from "../../../../components/AutocompleteController";
import SwitchController from "../../../../components/SwitchController";

export default function OrderWithdrawalView({ formValidation, withdrawlOptionList }) {
  const { storeList } = useStoreState();

  const {
    register,
    formState: { errors: formErrors },
    setValue,
    getValues,
    watch,
    reset,
    trigger,
  } = formValidation;
  const { auth } = useRoleAuthorization();

  useEffect(() => {
    reset({
      isWithdrawal: false,
      staff: null,
    });
  }, []);

  useEffect(() => {
    if (getValues("isWithdrawal") === false) {
      reset((val) => ({
        ...val,
        isWithdrawal: false,
        staff: null,
      }));
    }
  }, [getValues("isWithdrawal")]);

  return (
    <>
      {auth.checkModulePrivilege(
        PrivilegeModules.material_request,
        PrivilegeActions.withdrawal,
      ) && (
        <FormProvider {...formValidation}>
          <Grid container className={"form"}>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant={"h2"}>Staff Withdrawal</Typography>
                    <SwitchController name={"isWithdrawal"} />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                      <AutocompleteController
                        name={'staff'}
                        disabled={!getValues("isWithdrawal")}
                        required={getValues("isWithdrawal")}
                        label={'Staff'}
                        options={withdrawlOptionList}
                        getOptionLabel={(option) =>
                          `${option.username}-${option.name}`
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
