import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Search,
  Clear,
  KeyboardArrowDown,
  Event,
  PlaylistPlay,
  ArrowBack,
} from "@mui/icons-material";
import { ColumnConfig } from "./data-table/column-config";
import { withRouter } from "react-router-dom";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useUserState } from "../../context/UserContext";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import moment from "moment";
import downloadCSV from "../../helper/download-csv";
import {
  useLayoutDispatch,
  useLayoutState,
  toggleRightbar,
  setRighbarContent,
} from "../../context/LayoutContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import * as _ from "lodash";

// styles
import useStyles from "./styles";
import MobileRequestFilters from "./components/Mobile/MobileRequestFilters";
import Swal from "sweetalert2";
import { MATERIAL_REQUEST_STATUS } from "../../data/constants";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DateFilter from "../../components/DateFilter";

export function MaterialRequest(props) {
  const classes = useStyles();

  const [listRequest, setListRequest] = useState([]);
  const [installerList, setInstallerList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [installerFilter, setInstallerFilter] = useState(null);
  const [staffFilter, setStaffFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [projectFilter, setProjectFilter] = useState(null);
  const [fromRequestDate, setFromRequestDate] = useState(null);
  const [toRequestDate, setToRequestDate] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [companyFilter, setCompanyFilter] = useState(null);

  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const { userInfo } = useUserState();
  const { auth } = useRoleAuthorization();
  const { width } = useWindowDimensions();
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });

  const [refresh, setRefresh] = useState(false); // refresh list

  const projectFilterOptions = [
    {
      label: "YES",
      value: true,
    },
    {
      label: "NO",
      value: false,
    },
  ];

  // pagination states
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);

  useEffect(() => {
    document.title = "Material Request List";

    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.material_request,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    loadUserOption();
  }, []);

  // Get List Of Orders
  const { sendRequest, isLoading } = useHttpRequest();

  // set filtered data
  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, [
    statusFilter,
    installerFilter,
    staffFilter,
    fromRequestDate,
    toRequestDate,
    projectFilter,
    companyFilter,
  ]);

  useEffect(() => {
    if (!textFilter) {
      setRefresh(true);
    }
  }, [textFilter]);

  useMemo(() => {
    if (auth.isPrivilegeDataLoaded()) {
      let defaultStatusForFilter = "";

      if (
        auth.checkModulePrivilege(
          PrivilegeModules.material_request,
          PrivilegeActions.filter_status_pending,
          false,
        )
      ) {
        defaultStatusForFilter = "PENDING";
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.material_request,
          PrivilegeActions.filter_status_approved,
          false,
        )
      ) {
        defaultStatusForFilter = "APPROVED";
      } else if (
        auth.checkModulePrivilege(
          PrivilegeModules.material_request,
          PrivilegeActions.filter_status_closed,
          false,
        )
      ) {
        defaultStatusForFilter = "CLOSED";
      }

      setStatusFilter(defaultStatusForFilter);
    }
  }, [userInfo]);

  const columns = ColumnConfig({
    allowedViewDetail: auth.checkModulePrivilege(
      PrivilegeModules.material_request,
      PrivilegeActions.view_detail,
    ),
  });

  const statusList = Object.values(MATERIAL_REQUEST_STATUS);

  const isAbleToDownloadReport = useCallback(() => {
    return auth.checkModulePrivilege(
      PrivilegeModules.material_request,
      PrivilegeActions.download_report,
    );
  }, [userInfo]);

  // set right bar for small devices
  const smallDeviceWidth = 1200;
  const mobileFilterProps = {
    installerList,
    staffList,
    staffFilter,
    statusList,
    installerFilter,
    setInstallerFilter,
    setStaffFilter,
    statusFilter,
    setStatusFilter,
    projectFilter,
    projectFilterOptions,
    setProjectFilter,
    fromRequestDate,
    toRequestDate,
    setFromRequestDate,
    setToRequestDate,
    companyList,
    companyFilter,
    setCompanyFilter,
  };

  useEffect(() => {
    // set right bar drawer content for small devices
    if (width <= smallDeviceWidth) {
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MobileRequestFilters key={"rb-body"} {...mobileFilterProps} />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [
    installerFilter,
    staffFilter,
    statusFilter,
    projectFilter,
    fromRequestDate,
    toRequestDate,
    installerList,
    staffList,
    width,
  ]);

  async function downloadReport() {
    const csvData = [];

    const response = await getPaginatedData(1, 0, getFilterValues(), sortBy);
    const data = response.data?.requests || [];

    if (data.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "No data to export.",
      });
      return;
    }

    for (const order of data) {
      // Order Header
      csvData.push({
        "No.": order.incrementId,
        Team: order?.username,
        "Document No.": order.pkrNo,
        "Fulfill Date": order?.fulfillDate
          ? moment(order?.fulfillDate).format("DD/MM/YYYY HH:mm:ss")
          : "",
        Store: order?.storeCode,
        "Project Manager": order?.projectManager
          ? `${order?.projectManager?.userId}-${order?.projectManager?.userName}`
          : "",
        "Project Name": order?.project
          ? `${order?.project.projectName}-${order?.project.projectId}`
          : "",
        Withdrawal: order?.isWithdrawal ? "YES" : "NO",
        "Staff ID": order?.staffId,
        Company: order?.company,
        Code: "",
        Description: "",
        "Qty.": "",
        "Unit Price": "",
        Amount: "",
        "Total Amount": order?.totalPrice,
      });

      // Order Lines
      for (const item of order?.items) {
        csvData.push({
          "No.": "",
          Team: "",
          "Document No.": "",
          "Fulfill Date": "",
          Store: "",
          "Project Manager": "",
          "Project Name": "",
          Code: item.code,
          Description: item.description,
          "Qty.": item.requestedQty,
          "Unit Price": item.price,
          Amount: item.price * item.requestedQty,
          "Total Amount": "",
        });
      }
      csvData.push({});
    }
    const today = moment().format("YYYYMMDD_HHmmss");
    const filename = `MATERIAL_REPORT_${today}.csv`;
    downloadCSV(csvData, filename);
  }

  // populate installer list
  function loadUserOption() {
    sendRequest(`/v1/user/get-list-by-role/option_installer`, "GET", {}).then(
      (response) => {
        const list = response.data?.users || [];
        setInstallerList(list);
      },
    );

    sendRequest(`/v1/user/get-list-by-role/option_withdrawal`, "GET", {}).then(
      (response) => {
        const list = response.data?.users || [];
        setStaffList(list);
      },
    );

    sendRequest(`/v1/company?status=true`, "GET", {}).then((response) => {
      const list = response.data || [];
      setCompanyList(list);
    });
  }

  function filter() {
    getPaginatedData(page, pageSize, getFilterValues(), sortBy).then(
      (response) => {
        const filteredList = response.data?.requests || [];
        setListRequest(filteredList);
        setCollectionSize(response.data?.total);
        return () => {
          return filteredList;
        };
      },
    );
  }

  function getFilterValues() {
    return {
      text: textFilter.trim(),
      status: statusFilter ? statusFilter : undefined,
      installer: installerFilter ? installerFilter._id : undefined,
      company: companyFilter ? companyFilter.code : undefined,
      staff: staffFilter ? staffFilter.username : undefined,
      project: projectFilter ? projectFilter.value : undefined,
      from: fromRequestDate ? getFromDate(fromRequestDate) : undefined,
      to: toRequestDate ? getToDate(toRequestDate) : undefined,
    };
  }

  function getPaginatedData(page, pageSize, filters, sortBy = undefined) {
    return sendRequest(
      `/v1/material-order/pagination/filter?filters=${JSON.stringify(
        filters,
      )}&page=${page}&pageSize=${pageSize}
        ${
          sortBy?.column && sortBy?.direction
            ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
            : ""
        }`,
      "GET",
      {},
    );
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  };

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 10 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant={"h1"}>Material Request List</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {auth.checkModulePrivilege(
            PrivilegeModules.material_request,
            PrivilegeActions.add,
          ) && (
            <Button
              className={"primary"}
              onClick={() => props.history.push("new")}
            >
              New
            </Button>
          )}
          {isAbleToDownloadReport() && (
            <Button className={"primary"} onClick={() => downloadReport()}>
              Download Report
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={10} sm={10} md={10} lg={2}>
                <TextField
                  autoComplete={"off"}
                  id={"material-request-searchbox"}
                  variant={"outlined"}
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {width >= smallDeviceWidth ? (
                <>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"installer-filter"}
                      options={installerList}
                      getOptionLabel={(option) =>
                        `${option.username}-${option.name}`
                      }
                      value={installerFilter}
                      onChange={(e, newValue) => setInstallerFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Team"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>

                  {auth.checkModulePrivilege(
                    PrivilegeModules.material_request,
                    PrivilegeActions.withdrawal,
                  ) && (
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                      <Autocomplete
                        id={"staff-filter"}
                        options={staffList}
                        getOptionLabel={(option) =>
                          `${option.username}-${option.name}`
                        }
                        value={staffFilter}
                        onChange={(e, newValue) => setStaffFilter(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Staff"}
                            variant={"outlined"}
                          />
                        )}
                        popupIcon={<KeyboardArrowDown />}
                      />
                    </Grid>
                  )}

                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"status-filter"}
                      value={statusFilter}
                      onChange={(e, newValue) => {
                        setStatusFilter(newValue);
                      }}
                      options={statusList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Status"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"From"}
                      value={fromRequestDate}
                      maxDate={toRequestDate}
                      onChange={(newValue) => setFromRequestDate(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <DateFilter
                      label={"To"}
                      value={toRequestDate}
                      minDate={fromRequestDate}
                      onChange={(newValue) => setToRequestDate(newValue)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"project-filter"}
                      value={projectFilter}
                      onChange={(e, newValue) => {
                        setProjectFilter(newValue);
                      }}
                      options={projectFilterOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"For Project"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Autocomplete
                      id={"company-filter"}
                      options={companyList}
                      getOptionLabel={(option) =>
                        `${option.code}-${option.name}`
                      }
                      value={companyFilter}
                      onChange={(e, newValue) => setCompanyFilter(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Company"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => toggleRightbar(layoutDispatch)}
                    className={classes.drawerToggleBtn}
                  >
                    {layoutState.isRightbarOpened ? (
                      <ArrowBack className={classes.toggleRightBarIcon} />
                    ) : (
                      <PlaylistPlay className={classes.toggleRightBarIcon} />
                    )}
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTable
                  fixedHeader={true}
                  persistTableHead={true}
                  columns={columns}
                  data={listRequest}
                  sortServer
                  onSort={sortByColumn}
                  pagination
                  paginationServer
                  paginationDefaultPage={page}
                  paginationPerPage={pageSize}
                  paginationTotalRows={collectionSize}
                  onChangeRowsPerPage={(count) =>
                    handleRowsPerPageChanged(count)
                  }
                  onChangePage={(page) => handlePageChanged(page)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(MaterialRequest);
