import {
  DeleteOutline as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  KeyboardArrowDown, Clear
} from "@mui/icons-material";
import { IconButton, Switch, InputBase, TextField, Autocomplete } from "@mui/material";
import "./../Styles.css";

export const fnbStoreCategoryTableColumnConfig = (
  onRemoveRow,
  checkForDuplicatedCategory,
  onSelectImage,
  onChangeCategory,
  imagePreview,
  onRemoveImage,
  sortCategory,
  kitchenChefList,
  disabled,
) => [
  {
    name: "",
    width: "50px",
    button: true,
    cell: (row, index) => (
      <IconButton onClick={() => onRemoveRow(index)} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    ),
  },
  {
    name: "Seq. No.",
    width: "70px",
    cell: (row, index) => {
      return (
        <InputBase
          disabled={disabled}
          variant={"outlined"}
          value={row.seqNo}
          onBlur={(e) => sortCategory(index, Number(e.target.value))}
          onChange={(e) => onChangeCategory("seqNo", index, e.target.value)}
        />
      );
    },
  },
  {
    name: "Name",
    width: "300px",
    cell: (row, index) => {
      return (
        <InputBase
          disabled={disabled}
          variant={"outlined"}
          value={row.name}
          onBlur={(e) =>
            checkForDuplicatedCategory("name", index, e.target.value)
          }
          onChange={(e) => onChangeCategory("name", index, e.target.value)}
        />
      );
    },
  },
  {
    name: "Kitchen Chef",
    minWidth: "400px",
    cell: (row, index) => {
        return <Autocomplete disabled={disabled}
            options={!row.kitchenChefs ? kitchenChefList:kitchenChefList.filter(
              (chef) => !row.kitchenChefs.some((selectedChef) => chef.username === selectedChef.username)
            )}
            getOptionLabel={(option) => option.hasOwnProperty('name') ? option.name : option}
            value={row.kitchenChefs}
            onChange={(e, newValue) => 
              {
                onChangeCategory('kitchenChefs', index, newValue)
              }
            }
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            multiple
            popupIcon={<KeyboardArrowDown />}
            ChipProps={{ deleteIcon: <Clear /> }}
        />
    }
  },
  {
    name: "Picture (500x500)",
    width: "100px",
    center: true,
    cell: (row, index) => {
      return (
        <div className={"MuiFileForm-root"}>
          <IconButton
            className={row.url != "" ? "hide" : ""}
            onClick={() => onSelectImage(index)}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            className={row.url == "" ? "hide" : "show"}
            onClick={() => onRemoveImage(index)}
            disabled={disabled}
          >
            <RemoveIcon />
          </IconButton>
          <div className={"MuiFileForm-img"}>
            <img
              className={row.url == "" ? "hide" : "show"}
              src={row.isNewImage ? row.imageUrl_Local : row.url}
              onClick={(e) => imagePreview(row)}
            />
          </div>
        </div>
      );
    },
  },
  {
    name: "Exclude Server",
    center: true,
    width: '100px',
    cell: (row, index) => {
      return (
        <Switch
          checked={row.isExcludeFromServerQueue}
          onChange={(e, value) => onChangeCategory("isExcludeFromServerQueue", index, value)}
          inputProps={{ "aria-label": "controlled" }}
          disabled={disabled}
        />
      );
    },
  },
  {
    name: "Enable",
    width: "80px",
    center: true,
    selector: (row) => row.status,
    cell: (row, index) => {
      return (
        <Switch
          checked={row.status}
          onChange={(e, value) => onChangeCategory("status", index, value)}
          inputProps={{ "aria-label": "controlled" }}
          disabled={disabled}
        />
      );
    },
  },
];
